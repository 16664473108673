import { createRouter, createWebHistory } from 'vue-router';
import { MloginStatusCheck } from '@/utils';
import { ElMessage } from 'element-plus';

const routes = [{
    path: '/',
    component: () =>
        import('../views/OfficialPage.vue'),
    meta: {
        title: '广西米诺网络技术有限公司'
    }
},
{
    path: '/official',
    component: () =>
        import('../views/OfficialPage.vue'),
    meta: {
        title: '广西米诺网络技术有限公司'
    }
},
{
    path: '/guide',
    component: () =>
        import('../views/GuidePage.vue'), // 替换成你的主页组件
    meta: {
        title: '广西米诺网络技术有限公司'
    }
},
{
    path: '/print',
    component: () =>
        import('../views/PrintPage.vue'),
    meta: { title: '本地文件' },
    beforeEnter: (to, from, next) => {
        // 解码路由参数，并将其作为 props 传递给组件
        to.params.data1 = decodeURIComponent(to.query.data1 || '');
        to.params.data2 = decodeURIComponent(to.query.data2 || '');
        to.params.fileId = decodeURIComponent(to.query.fileId || '');
        to.params.auto = decodeURIComponent(to.query.auto || '');
        to.params.token = decodeURIComponent(to.query.token || '');
        next();
    },
},
{
    path: '/h5/service',
    name: 'servicepage',
    component: () =>
        import('../views/ServicePage.vue'), // 替换成你的关于页面组件
    meta: {
        title: '在线客服'
    },
    beforeEnter: (to, from, next) => {
        // 解码路由参数，并将其作为 props 传递给组件
        to.params.data1 = decodeURIComponent(to.query.data1 || '');
        to.params.data2 = decodeURIComponent(to.query.data2 || '');
        to.params.data3 = decodeURIComponent(to.query.data3 || '');
        to.params.data4 = decodeURIComponent(to.query.data4 || '');
        next();
    },
},
{
    path: '/h5/service_list',
    component: () =>
        import('../views/ServerList.vue'), // 替换成你的关于页面组件
    meta: {
        title: '顾客列表'
    },
    beforeEnter: (to, from, next) => {
        // 解码路由参数，并将其作为 props 传递给组件
        to.params.data1 = decodeURIComponent(to.query.data1 || '');
        to.params.data2 = decodeURIComponent(to.query.data2 || '');
        to.params.data3 = decodeURIComponent(to.query.data3 || '');
        next();
    },
},
{
    path: '/h5/service_login',
    component: () =>
        import('../views/ServicePages/ServiceLogin.vue'),
    meta: {
        title: '客服登陆'
    }
},
{
    path: '/h5/service_register',
    component: () =>
        import('../views/ServicePages/ServiceRegister.vue'),
    meta: {
        title: '客服注册'
    }
},
{
    path: '/h5/service_work',
    component: () =>
        import('../views/ServicePages/ServiceWork.vue'),
    meta: {
        title: '客户咨询'
    }
},
{
    path: '/h5/doc_view',
    component: () =>
        import('../src/components/mobile/DocView.vue'),
    meta: {
        title: '打印预览'
    },
    beforeEnter: (to, from, next) => {
        // 解码路由参数，并将其作为 props 传递给组件
        to.params.token = decodeURIComponent(to.query.token || '');
        to.params.session = decodeURIComponent(to.query.session || '');
        to.params.fileTmpName = decodeURIComponent(to.query.fileTmpName || '');
        next();
    },
},
{
    path: '/h5/user_agree',
    component: () =>
        import('@/components/mobile/UserAgree.vue'),
    meta: { title: '用户协议' },
    beforeEnter: (to, from, next) => {
        // 解码路由参数，并将其作为 props 传递给组件
        to.params.data1 = decodeURIComponent(to.query.data1 || '');
        to.params.data2 = decodeURIComponent(to.query.data2 || '');
        next();
    },
},
{
    path: '/mobile/store_status',
    component: () =>
        import('@/components/mobile/StoreStatus.vue'),
    meta: {
        title: '门店状态'
    }
},
{
    path: '/mobile/money_manage',
    component: () =>
        import('@/components/mobile/MoneyManage.vue'),
    meta: {
        title: '资金管理'
    }
},
{
    path: '/mobile/phone_exchange',
    component: () =>
        import('@/components/mobile/PhoneExchange.vue'),
    meta: {
        title: '变更号码'
    }
},
{
    path: '/mobile/new_order',
    component: () =>
        import('@/components/mobile/NewOrder.vue'),
    meta: {
        title: '新订单'
    }
},
{
    path: '/mobile/wait_order',
    component: () =>
        import('@/components/mobile/WaitOrder.vue'),
    meta: {
        title: '待处理订单'
    }
},
{
    path: '/mobile/service',
    component: () =>
        import('@/components/mobile/ServicePage.vue'),
    meta: {
        title: '官方客服'
    }
},
{
    path: '/mobile/questions',
    component: () =>
        import('@/components/mobile/NormalQs.vue'),
    meta: {
        title: '常见问题'
    }
},
{
    path: '/mobile/order_map',
    component: () =>
        import('@/components/mobile/OrderMap.vue'),
    meta: {
        title: '订单列表'
    }
},
{
    path: '/mobile/store_feedback',
    component: () =>
        import('@/components/mobile/StoreFeedback.vue'),
    meta: {
        title: '问题反馈'
    }
},
{
    path: '/mobile/register',
    component: () =>
        import('@/components/mobile/RegisterPage.vue'),
    meta: {
        title: '门店注册'
    }
},

{
    path: '/mobile/mappage',
    component: () =>
        import('@/components/mobile/MapPage.vue'),
    meta: {
        title: '地图选点'
    }
},
{
    path: '/mobile/registerGuide',
    component: () =>
        import('@/components/mobile/RegisterGuide.vue'),
    meta: {
        title: '门店入驻'
    }
},
{
    path: '/official/miniIntroduce',
    component: () =>
        import('../views/OfficialPages/MiniIntroduce.vue'),
    meta: {
        title: '印联云打印小程序'
    }
},
{
    path: '/official/ManageSystem',
    component: () =>
        import('../views/OfficialPages/ManageSystem.vue'),
    meta: {
        title: '印联云打印管理端'
    }
},
{
    path: '/wechatguide',
    component: () =>
        import('../views/WechatGuide.vue'),
    meta: {
        title: '印联云打印'
    }
},
{
    path: '/manage/homepage',
    component: () =>
        import('../views/ManagePages/ManageHome.vue'),
    meta: {
        title: '印联管理平台'
    },
},
{
    path: '/manage/managerlogin',
    component: () =>
        import('../views/ManagePages/MangeLogin.vue'),
    meta: {
        title: '管理登陆'
    },
},
{
    path: '/manage/storedetail',
    component: () =>
        import('../views/ManagePages/StoreDetail.vue'),
    meta: {
        title: '门店详情'
    },
},
{
    path: '/:catchAll(.*)', // 捕获所有未匹配的路由
    redirect: '/official' // 重定向到 /manage/homepage
},
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior: () => ({ y: 0 }), // 确保在重定向后滚动到页面顶部
});

// 移动设备检测函数
function isMobileDevice () {
    const userAgent = navigator.userAgent;
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    return isMobile;
}

// 微信环境检测
function wechatCheck () {
    const isWeixinBrowser = /micromessenger/i.test(navigator.userAgent)
    if (isWeixinBrowser) {
        // console.log('当前浏览器是微信浏览器')
        return true
    } else {
        // console.log('当前浏览器不是微信浏览器')
        return false
    }
}

router.beforeEach((to, from, next) => {
    const isMobile = isMobileDevice();
    const isWechat = wechatCheck()
    const check = to.path.split('/')[1];
    if (isMobile && check != 'print') {
        if (isMobile && !isWechat) {
            if (to.path !== '/wechatguide') {
                next('/wechatguide', { replace: true }); // 只在不是微信引导页时重定向
            } else {
                next(); // 如果已经在微信引导页，继续
            }
            return; // 终止后续代码执行
        }

        // 如果是移动设备，并且当前不是在引导页
        if (check == 'mobile') {
            if (to.path.split('/')[2] === '/mobile/order_map') {
                next('/mobile/order_map', { replace: true });
            } else {
                next(); // 如果已经在引导页，继续
            }
        } else if (check == 'h5') {
            next();
        } else if (to.path !== '/guide') {
            // console.log('非引导页跳转');
            next('/guide', { replace: true }); // 尝试重定向到引导页
        } else {
            next(); // 如果已经在引导页，继续
        }
    } else if (isMobile && check == 'print') {
        next();
    } else if (!isMobile) {
        // 管理端
        if (to.path != '/official' && to.path != '/') {
            MloginStatusCheck().then((status) => {
                if (!status && to.path != '/manage/managerlogin') {
                    ElMessage.error("登录超时");
                    next('/manage/managerlogin')
                } else {
                    next();
                }
            }).catch((error) => {
                next('/manage/managerlogin', { replace: true });
            });
        } else {
            next();
        }
    }
});

// 重置重定向标志
router.afterEach(() => { });

export default router;